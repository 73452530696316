/**
 * React and utilities
 */
import React, { useCallback } from 'react';

/**
 * Using Styled Components for CSS
 */
import styled from 'styled-components';
import { maxWidthForIsMobile } from '../../styles/GlobalStyles';
import ButtonStyled from '../Form/Button';
import StyledCurrencyField from '../Form/CurrencyField';
import StyledCustomSelect from '../Form/CustomSelect';
import StyledTextField from '../TextField';

/**
 * The header navigation bar.
 */
const YourLoan = ({
  className,
  setStep,
  setData,
  data: { loanAmount, loanProgram, rate, yearsSinceMortgage },
}) => {
  const handleCurrencyFieldClick = (event) => {
    event.target.setSelectionRange(
      event.target.value.length,
      event.target.value.length
    );
  };

  const handleNextStep = useCallback(() => {
    setStep('financialSituation');
  }, [setStep]);

  return (
    <div className={className}>
      <h2>Tell us about your loan</h2>

      <span>What is your loan amount?</span>

      <StyledCurrencyField
        setValue={(value) => setData({ loanAmount: value })}
        value={loanAmount}
        adornment={'$'}
        onChange={handleCurrencyFieldClick}
      />

      <span>What is your old mortgage rate?</span>

      <StyledCurrencyField
        setValue={(value) => setData({ rate: value })}
        value={rate}
        placeholder="5"
        adornment={'%'}
        onChange={handleCurrencyFieldClick}
      />

      <span>What type of mortgage do you have?</span>

      <StyledCustomSelect
        value={loanProgram}
        onChange={({ target }) => setData({ loanProgram: target.value })}
        options={[
          {
            value: '30 yr fixed',
            label: '30 Year Fixed',
          },
          {
            value: '15 yr fixed',
            label: '15 Year Fixed',
          },
          {
            value: '5/1 ARM',
            label: '5-1 ARM',
          },
          {
            value: 'Other',
            label: 'Other',
          },
          {
            value: 'Not sure',
            label: 'Not sure',
          },
        ]}
      />

      <span>How many years ago did you take out your mortgage?</span>

      <StyledTextField
        setValue={(value) => setData({ yearsSinceMortgage: value })}
        value={yearsSinceMortgage}
        adornment={'$'}
        onChange={handleCurrencyFieldClick}
        type="number"
        passwordsMatch={'/d+/g'}
      />

      <div id="action-container">
        <ButtonStyled id="back-button" onClick={() => setStep('home')}>
          Back
        </ButtonStyled>

        <ButtonStyled
          id="call-to-action-button"
          variant="contained"
          onClick={handleNextStep}
          disabled={!loanAmount || !loanProgram || !rate || !yearsSinceMortgage}
        >
          Save & Next
        </ButtonStyled>
      </div>
    </div>
  );
};

/**
 * The Final Exported Container
 */
const StyledYourLoan = styled(YourLoan)`
  @media screen and (min-width: 800px) {
    ${StyledCurrencyField},
    ${StyledCustomSelect},
     ${StyledTextField} {
      max-width: 300px;
    }
  }

  ${StyledCurrencyField},
  ${StyledCustomSelect} {
    margin-bottom: 15px;
    &&& .MuiInputLabel-root,
    input {
      color: black;
    }
  }

  & > h2 {
    font-size: 2.5em;
    font-weight: 400;
    font-family: Majorant-Medium;

    margin-bottom: 59px;
  }

  & > span {
    font-weight: 400;
    font-family: Majorant-Medium;
    display: block;

    margin-top: 10px;
    margin-bottom: 16px;
  }

  #action-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10vh;

    button {
      height: 43px;
    }

    #back-button {
      width: 159px;
    }

    #call-to-action-button {
      width: 177px;
    }

    @media screen and (max-width: ${maxWidthForIsMobile}) {
      flex-direction: column;
      gap: 10px;

      #back-button,
      #call-to-action-button {
        width: 100%;
      }

      ${StyledCustomSelect},
      ${StyledCurrencyField},
      ${StyledTextField} {
        max-width: 100%;
      }
    }
  }
`;

StyledYourLoan.displayName = 'YourLoan';

export default StyledYourLoan;
