/**
 * React and friends
 */
import React from 'react';

/**
 * Global styles and theme
 */
import '../styles/layout.css';
import { ThemeProvider } from '@material-ui/core/styles';
import GlobalTheme, { theme } from '../styles/GlobalTheme';

/**
 * Component level styling
 */
import styled from 'styled-components';

/**
 * Provider for Snackbar hook
 */
import { SnackbarProvider } from '../hooks/useSnackbar';

import MobileHeader from './MobileHeader';
import Header from './Header';
import useModal, { ModalContextProvider } from '../hooks/useModal';

import useIntercomChat from '../hooks/useIntercomChat';

/**
 * Location provider
 */
import { Location } from '@reach/router';
import Footer from './Footer';
import { MortgageAdvisorProvider } from '../hooks/useMortgageAdvisor';
import { RefinanceToolProvider } from '../hooks/useRefinanceTool';

import { ModalWrapper } from './Modal';

// Make room for mobile nav
export const ContentWrapper = styled('div')`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  & > #gatsby-focus-wrapper {
    width: 100%;
    margin: 0 auto;
    max-width: 1300px;
    padding: 0 60px;
    @media ${theme.mq.tabletMax} {
      overflow-x: hidden;
    }
  }
`;

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
export default ({ children }) => {
  /**
   * Checking the window size (refactor to a isMobile check)
   */
  useIntercomChat();

  /**
   * Control the modal
   */

  return (
    <Location>
      {({ location: { pathname: currentPage } }) => (
        <ThemeProvider theme={GlobalTheme}>
          <SnackbarProvider>
            <ModalContextProvider>
              <MortgageAdvisorProvider>
                <RefinanceToolProvider>
                  <MobileHeader currentPage={currentPage} />
                  <Header currentPage={currentPage} />
                  <ContentWrapper>{children}</ContentWrapper>
                  <ModalWrapper />
                  <form
                    name="partnerSignup"
                    data-netlify="true"
                    hidden
                    netlify-honeypot="bot-field"
                  >
                    <input type="text" name="name" />
                    <input type="text" name="email" />
                    <input type="text" name="role" />
                  </form>
                  <Footer />
                </RefinanceToolProvider>
              </MortgageAdvisorProvider>
            </ModalContextProvider>
          </SnackbarProvider>
        </ThemeProvider>
      )}
    </Location>
  );
};
