/**
 * React and utilities
 */
import React, { useCallback, useState } from 'react';

/**
 * Using Styled Components for CSS
 */
import styled from 'styled-components';
import {
  blockedStatesForMortgageTools,
  apiUrl,
  loanAmountFactor,
  states,
} from '../../constants';
import { maxWidthForIsMobile } from '../../styles/GlobalStyles';
import ButtonStyled from '../Form/Button';
import StyledCurrencyField from '../Form/CurrencyField';
import StyledCustomSelect from '../Form/CustomSelect';
import WaitlistModal from '../WaitlistModal';

const yearsBeforeMovingList = [
  {
    value: 10,
    label: 'No different than is typical (around 10)',
  },
  { value: 2, label: '2' },
  { value: 6, label: '6' },
  { value: 14, label: '14+' },
];

function Home({
  className,
  setData,
  setStep,
  data: { state, purchasePrice, yearsBeforeMoving, tool, loanAmount, hasOffer },
  addMessage,
}) {
  const [exitModalOpen, setExitModalOpen] = useState(false);
  const filteredStates = states.filter(
    (state) => !blockedStatesForMortgageTools.includes(state)
  );
  const handleNextStep = useCallback(() => {
    if (blockedStatesForMortgageTools.includes(state)) {
      return setExitModalOpen(true);
    }

    if (tool === 'rippedOff' && purchasePrice < loanAmount) {
      return addMessage(
        'The value of your house must be greater than loan amount',
        false
      );
    }

    if (tool === 'mortgageRefinance') {
      setData({ loanAmount: purchasePrice * loanAmountFactor });

      return setStep('yourLoan');
    }

    setStep('additionalInfo');
  }, [state, purchasePrice]);

  const handleSignUp = async (email) => {
    try {
      setExitModalOpen(false);

      const response = await fetch(`${apiUrl}/external/addUserToWaitList`, {
        method: 'POST',
        body: JSON.stringify({ usState: state, email }),
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      });

      const { message } = await response.json();

      addMessage(message);
    } finally {
    }
  };

  return (
    <div className={className}>
      <h1>Tell us about your home.</h1>

      <span>What state is it in?</span>

      <StyledCustomSelect
        label="Select one"
        onChange={({ target }) => setData({ state: target.value })}
        options={filteredStates.map((state) => ({
          value: state,
          label: state,
        }))}
        value={state}
      />

      <span>What is the value of your house?</span>

      <StyledCurrencyField
        adornment="$"
        setValue={(value) => setData({ purchasePrice: value })}
        value={purchasePrice}
        placeholder="300,000"
      />

      {!hasOffer && tool === 'rippedOff' && (
        <>
          <span>What is your loan amount?</span>

          <StyledCurrencyField
            setValue={(value) => setData({ loanAmount: value })}
            value={loanAmount}
            placeholder="250,000"
            adornment={'$'}
          />
        </>
      )}

      {!hasOffer && (
        <>
          <span>How many years do you expect to stay in your home?</span>

          <StyledCustomSelect
            onChange={({ target }) =>
              setData({ yearsBeforeMoving: target.value })
            }
            options={yearsBeforeMovingList}
            value={yearsBeforeMoving}
            className="select"
          />
        </>
      )}

      <div id="action-container">
        {tool !== 'mortgageRefinance' && (
          <ButtonStyled
            id="back-button"
            onClick={() => setStep(hasOffer ? 'existingOffer' : 'scenario')}
          >
            Back
          </ButtonStyled>
        )}

        <ButtonStyled
          disabled={
            !state || !purchasePrice || !yearsBeforeMoving || !loanAmount
          }
          id="call-to-action-button"
          onClick={handleNextStep}
          variant="contained"
        >
          Save & Next
        </ButtonStyled>
      </div>

      <WaitlistModal
        exitModalOpen={exitModalOpen}
        setExitModalOpen={setExitModalOpen}
        handleSignUp={handleSignUp}
      />
    </div>
  );
}

/**
 * The Final Exported Container
 */
const StyledHome = styled(Home)`
  ${StyledCustomSelect},
  ${StyledCurrencyField} {
    max-width: 300px;

    input {
      color: black;
    }
  }

  & > h1 {
    font-size: 2.5em;
    font-weight: 400;
    font-family: Majorant-Medium;
  }

  & > span {
    font-weight: 400;
    font-family: Majorant-Medium;
    display: block;

    margin-top: 59px;
    margin-bottom: 16px;
  }

  #action-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10vh;
    gap: 10px;

    button {
      height: 43px;
    }

    #back-button {
      width: 159px;
    }

    #call-to-action-button {
      width: 177px;
    }

    @media screen and (max-width: ${maxWidthForIsMobile}) {
      flex-direction: column;

      button,
      #call-to-action-button,
      #back-button {
        width: 100%;
      }

      ${StyledCustomSelect},
      ${StyledCurrencyField} {
        max-width: 100%;
      }
    }
  }
`;

StyledHome.displayName = 'Home';

export default StyledHome;
