/**
 * React and utilities
 */
import React from 'react';

/**
 * Using Styled Components for CSS
 */
import styled from 'styled-components';

import StyledButton from '../Form/Button';
import LabelledNumber from '../LabelledNumber';
import ResultValueStyled from '../ResultValue';
import MonthlyPaymentChart from './MonthlyChartPayment';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import { maxWidthForIsMobile } from '../../styles/GlobalStyles';
import PaymentsOverTimeGraph from './PaymentsOverTimeGraph';
import { trackEvent } from '../../analytics';
import { getConversionValue } from '../../../utils';

const BarGraph = React.lazy(() => import('./BarGraph'));
const LineGraph = React.lazy(() => import('./LineGraph'));

const GOAL_TO_LOAN_PRODUCT = {
  'Minimize monthly payment (for sure)': '30 year fixed rate',
  'Minimize the total interest I pay': '15 year fixed rate',
  'Repay my debt as quickly as possible (with higher monthly payments)':
    '15 year fixed rate',
  'Minimize my average monthly payment (but with risk - monthly payments can go up and down)':
    '5/1 ARM',
};

/**
 * The header navigation bar.
 */
const ResultOptions = ({
  className,
  bestRates,
  data: {
    loanAmount,
    loanProgram,
    rate,
    goal,
    purpose,
    purchasePrice,
    totalFees,
    pointFees,
    tool,
    yearsSinceMortgage,
    hasOffer,
  },
  setStep,
}) => {
  const currentOffer = {
    source: 'current mortgage',
    appleRate: 5.9750000000000005,
    propertyValue: purchasePrice,
    loanToValue: loanAmount / purchasePrice,
    creditScore: '700',
    lenderName: 'current mortgage',
    thirdPartyFees: 0,
    combinedPointsAndLenderFees: 0,
    pointsCreditsDollarValue: 0,
    siteName: 'current mortgage',
    interestRate: rate,
    monthlyPayment: null,
    points: 0,
    purchasePrice: purchasePrice,
    ltv: loanAmount / purchasePrice,
    loanToValueAmount: loanAmount / purchasePrice,
    oldMonthlyPayment: null,
    priceDifference: 0,
    kappa: 0,
    channelLenderURL: '',
  };
  const [bestOffer, worstOffer] = returnOfferByPrice(bestRates);
  const [lenderList, histogramData] = getHistogramData(
    convertLendersToHistogramData([...bestRates, currentOffer])
  );
  const [channelName, channelUrl] = cleanChannelNameAndUrl(
    bestOffer?.source,
    bestOffer?.channelLenderURL
  );
  const { information, title } =
    tool === 'rippedOff'
      ? getToolTexts(
          rate,
          bestRates,
          bestOffer?.priceDifference,
          worstOffer?.priceDifference,
          hasOffer
        )
      : {};

  const { oldMonthlyPayment } = bestOffer;

  const closingCosts = parseFloat(
    (
      bestOffer?.combinedPointsAndLenderFees + bestOffer?.thirdPartyFees
    ).toFixed(3)
  );

  const hasIncreasedMonthlyPayment =
    oldMonthlyPayment - bestOffer?.monthlyPayment < 0;

  const handleOpenLender = () => {
    trackEvent('Redirect to Lender', {
      loanGroup: 'MORTGAGE',
      offerId: bestOffer.id,
      transaction_id: bestOffer.id,
      lender: bestOffer.lenderName,
      loanSource: bestOffer.source,
      loanLocation:
        tool === 'rippedOff' ? 'MORTGAGE_ADVISOR' : 'MORTGAGE_REFINANCE',
      value: getConversionValue('MORTGAGE'),
    });

    window.open(channelUrl, '_blank', 'noopener noreferrer');
  };

  const showCurrentOffer = tool !== 'mortgageRefinance' && hasOffer;

  const actualTotalFees = pointFees + totalFees;

  return (
    <div className={className}>
      <h1 align="center">Best Offer we Found for Your Scenario:</h1>

      <div id="benefits-container">
        <LabelledNumber
          title={!hasOffer ? 'Monthly Payment' : 'Monthly Savings'}
          amount={{
            value: parseFloat(
              (!hasOffer
                ? bestOffer?.monthlyPayment
                : oldMonthlyPayment - bestOffer?.monthlyPayment
              ).toFixed(3)
            ),
            type: 'currency',
          }}
          highlighted={hasOffer}
        />

        <ResultValueStyled
          title="Interest Rate"
          offer={{
            amount: {
              value: parseFloat(bestOffer?.interestRate.toFixed(3)),
              delta:
                !hasOffer && tool === 'rippedOff'
                  ? null
                  : bestOffer?.interestRate - rate,
            },
          }}
        />

        <ResultValueStyled
          title="Closing Costs (all)"
          offer={{
            amount: {
              value: closingCosts,
              type: 'currency',
              delta: !hasOffer
                ? null
                : Math.abs(
                    actualTotalFees - bestOffer?.combinedPointsAndLenderFees
                  ),
            },
            type: 'currency',
          }}
        />
      </div>

      <h3 id="scenario-title">{title}</h3>

      <h4 id="scenario-description">{information}</h4>
      {tool === 'rippedOff' && bestRates.length > 3 && hasOffer && (
        <div id="bar-graph">
          <React.Suspense fallback={<></>}>
            <BarGraph histogramData={histogramData} lenderList={lenderList} />
          </React.Suspense>
        </div>
      )}

      {tool === 'mortgageRefinance' && (
        <div id="bar-graph">
          <MonthlyPaymentChart
            oldMonthlyPayment={oldMonthlyPayment}
            monthlyPayment={bestOffer?.monthlyPayment}
            lenderName={bestOffer?.lenderName}
          />
        </div>
      )}

      <div id="data-container">
        <div id="mortgage-details">
          <h3>Mortgage Details</h3>

          <h1>${roundNumbersWithLocale(loanAmount)}</h1>

          <span>{GOAL_TO_LOAN_PRODUCT[goal]}</span>

          <span>{purpose}</span>

          <span>{bestOffer?.lenderName}</span>

          <subtitle>via {bestOffer?.source}</subtitle>
        </div>

        <div id="accordion-container">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="accordion-title-container">
                <h1>Total Closing Cost</h1>
                <span>Lender Fees + Points + Third Party Costs</span>
              </div>

              <span>${roundNumbersWithLocale(closingCosts)}</span>
            </AccordionSummary>

            <AccordionDetails>
              <div className="accordion-container">
                <div>
                  <p>Down Payment</p>
                  <span>
                    ${roundNumbersWithLocale(purchasePrice - loanAmount)}
                  </span>
                </div>

                <div className="accordion-details-right">
                  {showCurrentOffer && <p>Current</p>} <p>Best Offer</p>
                </div>

                <div>
                  <div className="mortgage-parameters">
                    <p>Upfront Lender Fees</p>

                    <div>
                      {showCurrentOffer && (
                        <span>${roundNumbersWithLocale(actualTotalFees)}</span>
                      )}
                      <span>
                        $
                        {roundNumbersWithLocale(
                          bestOffer?.combinedPointsAndLenderFees
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="mortgage-parameters">
                    <p>3rd Party Costs (est)</p>

                    <div>
                      {showCurrentOffer && <span>?</span>}
                      <span>${bestOffer?.thirdPartyFees}</span>
                    </div>
                  </div>

                  <div className="mortgage-parameters">
                    <p>Points</p>

                    <div>
                      {showCurrentOffer && (
                        <span>
                          {roundNumbersWithLocale(pointFees / loanAmount)}%
                        </span>
                      )}
                      <span>{roundNumbersWithLocale(bestOffer?.points)}%</span>
                    </div>
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <div className="accordion-title-container">
                <h1>Monthly Recurring Cost</h1>
              </div>

              <div>
                <span>
                  ${roundNumbersWithLocale(bestOffer?.monthlyPayment) || 0}
                </span>

                <div
                  className={
                    'accordion-increase ' +
                    (hasIncreasedMonthlyPayment ? 'red' : 'green')
                  }
                >
                  {hasOffer && (
                    <>
                      {hasIncreasedMonthlyPayment ? (
                        <ArrowUpwardIcon />
                      ) : (
                        <ArrowDownwardIcon />
                      )}{' '}
                      $
                      {roundNumbersWithLocale(
                        Math.abs(oldMonthlyPayment - bestOffer?.monthlyPayment)
                      )}
                    </>
                  )}
                </div>
              </div>
            </AccordionSummary>

            <AccordionDetails>
              {tool === 'rippedOff' && (
                <div id="amortization-schedule">
                  <div>Amortization Schedule</div>
                  <React.Suspense fallback={<></>}>
                    <LineGraph
                      guaranteedRate={bestOffer?.interestRate}
                      loanAmount={loanAmount}
                      loanProgram={loanProgram}
                    />
                  </React.Suspense>
                </div>
              )}

              {tool === 'mortgageRefinance' && (
                <PaymentsOverTimeGraph
                  lenderFees={
                    bestOffer?.pointsCreditsDollarValue +
                    bestOffer?.combinedPointsAndLenderFees
                  }
                  thirdPartyFees={bestOffer?.thirdPartyFees}
                  monthlyPayment={bestOffer?.monthlyPayment}
                  oldMonthlyPayment={oldMonthlyPayment}
                  yearsLeft={yearsSinceMortgage}
                />
              )}
            </AccordionDetails>
          </Accordion>
        </div>
      </div>

      <div id="edit-button">
        <StyledButton
          onClick={() =>
            setStep(tool === 'mortgageRefinance' ? 'home' : 'scenario')
          }
        >
          Edit my inputs
        </StyledButton>

        <StyledButton variant="contained" onClick={handleOpenLender}>
          Take me to {bestOffer.lenderName}
        </StyledButton>
      </div>

      <p>
        This website or lender is not paying us referral fees - it’s simply the
        best option we found
      </p>
    </div>
  );
};

/**
 * The Final Exported Container
 */
const StyledResultOptions = styled(ResultOptions)`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  .accordion-increase {
    display: flex;
    align-items: center;
    margin-top: 0.2rem;
    color: #24ab8b;
    font-size: 13px;
  }

  .red {
    color: red;
  }

  .mortgage-parameters {
    display: flex;
    justify-content: space-between;
    margin-top: 1.2rem;

    & > div {
      display: flex;

      & > span {
        width: 100px;
        text-align: right;
      }
    }
  }

  .green {
    color: #24ab8b;
  }

  .accordion-container {
    width: 100%;
    font-size: 13px;

    .accordion-details-right {
      display: flex;
      justify-content: flex-end;

      & > p {
        width: 100px;
        text-align: right;
        margin-top: 1.2rem;
      }
    }

    & > div:first-child {
      display: flex;
      justify-content: space-between;
    }
  }

  .accordion-title-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > h1 {
      color: #000;
      font-family: Majorant-Regular;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }

    & > span {
      font-feature-settings: 'cv11' on, 'ss01' on;
      color: rgba(0, 0, 0, 0.5);
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin-top: 0.2rem;
    }
  }

  #benefits-container {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 3rem;
    margin-top: 5rem;
    width: 100%;
  }

  #scenario-title {
    width: 50%;
    font-family: Majorant-Medium;
  }

  #scenario-description {
    width: 50%;
    font-weight: unset;
    margin-top: 1.5rem;
  }

  #amortization-schedule {
    height: 20rem;
    width: 25rem;
    min-width: 0px;
    margin-bottom: 5px;
  }

  #mortgage-details {
    display: flex;
    flex-direction: column;

    & > span:nth-child(3) {
      margin-top: 1rem;
    }

    & > subtitle {
      margin-top: 0.5rem;
    }

    & > h1 {
      color: #000;
      font-family: Majorant-Medium;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: -0.04em;
      line-height: 46px;
    }

    & > subtitle {
      font-feature-settings: 'cv11' on, 'ss01' on;
      color: rgba(0, 0, 0, 0.5);
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }

  #mortgage-data {
    display: flex;
    gap: 7rem;
    margin-top: 3rem;
  }

  #data-container {
    display: flex;
    align-items: center;
    gap: 7rem;
    margin: 3rem 0;
  }

  & > p {
    font-family: Majorant-Regular;
    color: grey;
    margin-top: 0.5rem;
    font-size: 12px;
    margin-bottom: 2rem;
  }

  #edit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    width: 70%;
  }

  #bar-graph {
    height: 25rem;
    width: 50%;
  }

  #accordion-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  @media screen and (max-width: ${maxWidthForIsMobile}) {
    #benefits-container {
      flex-direction: column;
      gap: 30px;
      margin-top: 30px;
    }

    #scenario-title,
    #scenario-description,
    #bar-graph {
      width: 100%;
    }

    #bar-graph {
      height: 20rem;
    }

    #data-container {
      flex-direction: column;
      width: inherit;
      gap: 30px;
    }

    #edit-button {
      flex-direction: column;
      width: 100%;
    }
  }
`;

StyledResultOptions.displayName = 'ResultOptions';

export default StyledResultOptions;

// TODO - Anything below here is old code from another folder that should be refactored or migrated

const getToolTexts = (rate, lenders, bestOfBest, bestOfWorst, hasOffer) => {
  let information = `We have searched through ${lenders.length} lenders to see how your current situation & offer stands. We compared all the closing costs of loans with an interest rate of ${rate}%, or if we only found a nearby interest rate from a particular lender we converted it to an offer of the same rate using points and credits for a fair comparison. Hover over a lender box to see details, the best lender is in blue.`;

  const lowResultTitle =
    bestOfBest === -1
      ? `You have the best price by $${roundNumbersWithLocale(
          Math.abs(bestOfWorst)
        )}. We are currently finding sparse results in searching for the best price for your scenario. Please check back at a later date.`
      : `The best price we found would save you $${roundNumbersWithLocale(
          Math.abs(bestOfBest)
        )} when taking into account both rate and total fees. We are currently finding sparse results in searching for the best price for your scenario. Please check back at a later date.`;

  let title = 'Cost Comparison for Same Loan';

  if (lenders.length < 3) {
    information = lowResultTitle;
    title = '';
  }

  if (!hasOffer) {
    information = '';
    title = '';
  }

  return { information, title, lowResultTitle };
};

const addToBucket = (lender, myDict, lenderPriceDict) => {
  // positive savings are in index 1, if it is null we do a slightly diff calculation for negative value buckets (floor value instead of ceiling -873 => -1000)
  if (typeof lender[1] === 'number') {
    let priceLocation = Math.ceil(lender[1] / 1000) * 1000;
    lenderPriceDict[lender[0]] = lender[1];
    if (!myDict[priceLocation]) {
      // if empty hash create an array with that lender
      myDict[priceLocation] = [lender[0]];
    } else {
      // add lender to an array of that price point (representing a x-axis location on histogram)
      myDict[priceLocation].push(lender[0]);
    }
  } else if (typeof lender[2] === 'number') {
    let priceLocation = Math.floor(lender[2] / 1000) * 1000;
    lenderPriceDict[lender[0]] = lender[2];
    if (!myDict[priceLocation]) {
      // if empty hash create an array with that lender
      myDict[priceLocation] = [lender[0]];
    } else {
      // add lender to an array of that price point (representing a x-axis location on histogram)
      myDict[priceLocation].push(lender[0]);
    }
  }
};

const convertLendersToHistogramData = (lenders) => {
  const thing = lenders.map((lender) => {
    const isPriceZero = lender.priceDifference === 0;
    const isPriceHigherThanZero = lender.priceDifference > 0;
    if (isPriceZero) {
      return [`${lender.lenderName}`, 0, null];
    }
    return [
      `${lender.lenderName}`,
      isPriceHigherThanZero ? lender.priceDifference : null,
      isPriceHigherThanZero ? null : lender.priceDifference,
    ];
  });
  return thing;
};

const isBestPrice = (lender, appleData) => {
  if (appleData[0][0] === lender) {
    return true;
  }
  return false;
};

const getHistogramData = (appleData) => {
  let histogramArray = [];
  let myDict = {};
  let lenderPriceDict = {}; // so we can look up exact price of each lender without searching array

  for (let lender of appleData) {
    addToBucket(lender, myDict, lenderPriceDict);
  }
  for (let price of Object.keys(myDict)) {
    let graphObject = {};
    graphObject['savings'] = price;
    for (let lender of myDict[price]) {
      graphObject[lender] = 1;

      if (isBestPrice(lender, appleData)) {
        graphObject[lender + 'Color'] = 'hsl(274, 70%, 50%)';
      } else {
        graphObject[lender + 'Color'] = 'hsl(274, 70%, 50%)';
      }
    }
    histogramArray.push(graphObject);
  }
  // sort from most to least savings
  histogramArray.sort((a, b) => b.savings - a.savings);

  return [Object.keys(lenderPriceDict), histogramArray];
};

export const createD3Data = (data) => {
  // return an array of objects, each object is a line, we will have 3 lines for interest component, principal component, remaining principal

  let principalData = {
    id: 'principal',
    data: [],
  };
  let interestData = {
    id: 'interest',
    data: [],
  };
  // first loop and get the principal data
  for (let [idx, element] of data.entries()) {
    if (idx === 0 || idx % 12 !== 0) continue;
    let principalObject = {
      x: idx,
      y: element[1],
    };
    let interestObject = {
      x: idx,
      y: element[2],
    };
    principalData.data.push(principalObject);
    interestData.data.push(interestObject);
  }
  return [principalData, interestData];
};

// returns both the best price difference, and if you have the best price returns how much better you have compared to the next best option
const returnOfferByPrice = (offers) => {
  const newOffers = offers.sort(
    (a, b) => b.priceDifference - a.priceDifference
  );
  return [newOffers[0], newOffers[newOffers.length - 1]];
};

export const cleanChannelNameAndUrl = (source, channelLenderURL) => {
  const betterNameString = 'Better.com';
  const bankrateNameString = 'Bankrate';
  const mndNameString = 'Mortgage News Daily';
  const ckNameString = 'Credit Karma';
  const sofiNameString = 'SoFi';

  const betterUrl = 'https://better.com/mortgage-rates/refinance/';
  const ckUrl = 'https://www.creditkarma.com/home-loans/mortgage-rates';
  const mndUrl = 'http://www.mortgagenewsdaily.com/mortgage_rates/compare/';
  const bankRateUrl = 'https://www.bankrate.com/mortgages/refinance-rates';
  const sofiUrl =
    'https://refer.sofi.com/c/2984730/1156103/11190?adcampaign=Mortgage&adnetwork=BD';

  if (source.match(/karma/i)) return [ckNameString, ckUrl];
  if (source.match(/mortgage news/i)) return [mndNameString, mndUrl];
  if (source.match(/mnd/i)) return [mndNameString, mndUrl];
  if (source.match(/bankrate/i)) return [bankrateNameString, bankRateUrl];
  if (source.match(/better/i)) return [betterNameString, betterUrl];
  if (source.match(/sofi/i)) return [sofiNameString, sofiUrl];

  return [source, channelLenderURL];
};

const roundNumbersWithLocale = (value) => {
  value = Math.round(value);
  return value.toLocaleString();
};
