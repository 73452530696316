/**
 * React and utilities
 */
import React from 'react';

/**
 * Using Styled Components for CSS
 */
import styled from 'styled-components';
import LinearProgress from '@material-ui/core/LinearProgress';

/**
 * Images
 */
import Group266 from '../../images/HomeFinance/Group 266.svg';
import { maxWidthForIsMobile } from '../../styles/GlobalStyles';

/**
 * Loading screen for offers
 */
const LoadingOffers = ({ className }) => {
  return (
    <div className={className}>
      <div id="content-container">
        <div id="market-container">
          <h2>Figuring out the best option for you</h2>

          <span>
            Please wait while we determine the best course of action for your
            scenario. If you have questions please reach out to
            info@solve.finance
          </span>
        </div>

        <img
          id="house-image"
          src={Group266}
          alt="Small house with money coming out of the roof"
        />
      </div>

      <LinearProgress id="progress" />
    </div>
  );
};

/**
 * The Final Exported Container
 */
const StyledLoadingOffers = styled(LoadingOffers)`
  margin: 10rem 0 3rem;
  display: flex;
  flex-direction: column;

  #progress {
    width: 100%;
  }

  #content-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
  }

  #market-container {
    display: flex;
    flex-direction: column;
    width: 40%;

    & > h2 {
      font-family: Majorant-Medium;
      font-size: 64px;
      font-weight: 500;
      letter-spacing: -0.04em;
      line-height: 120%;
      margin-bottom: 1rem;
      width: 100%;
    }

    & > span {
      font-family: Majorant-Regular;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: -0.04em;
      line-height: 120%;
      margin-bottom: 3rem;
      margin-bottom: 0;
      width: 70%;
    }
  }

  #house-image {
    width: 34%;
  }

  @media screen and (max-width: ${maxWidthForIsMobile}) {
    flex-direction: column;
    margin: 0;

    #house-image {
      width: 100%;
    }

    #content-container {
      flex-direction: column-reverse;
    }

    #market-container {
      display: flex;
      flex-direction: column;
      width: 100%;

      & > h2 {
        font-family: Majorant-Medium;
        font-size: 42px;
        font-weight: 500;
        letter-spacing: -0.04em;
        line-height: 120%;
        margin-bottom: 1rem;
      }

      & > span {
        font-family: Majorant-Regular;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.04em;
        line-height: 120%;
        margin-bottom: 3rem;
        margin-bottom: 0;
        width: 100%;
      }
    }
  }
`;

StyledLoadingOffers.displayName = 'LoadingOffers';

export default StyledLoadingOffers;
