/**
 * React and utilities
 */
import React, { useCallback } from 'react';
import { Link } from 'gatsby';
/**
 * Using Styled Components for CSS
 */
import styled from 'styled-components';
import StyledButton from '../Form/Button';
import RadioGroup from '../Form/RadioGroup';

/**
 * The header navigation bar.
 */
const Scenario = ({
  className,
  setStep,
  setData,
  data: { purpose = 'Purchase', hasOffer = true },
}) => {
  const handleNextStep = useCallback(() => {
    setData({ purpose, hasOffer });
    setStep(hasOffer ? 'existingOffer' : 'home');
  }, [setData, setStep, purpose, hasOffer]);

  return (
    <div className={className}>
      <h1 id="scenario-title">
        Optimize Your Mortgage. Compare Costs Instantly
      </h1>
      <h2 id="scenario-description">
        Navigate the Mortgage Market With Confidence
      </h2>

      <p style={{ marginTop: '-24px', marginBottom: '24px' }}>
        Understanding different mortgage products and finding the best mortgage
        and lender can be challenging, but with the right information and tools,
        it can be made simple. Get started immediately below. Or check out our
        guides on{' '}
        <Link to="/resources/how-to-find-the-right-mortgage-lender/">
          how to find the best mortgage
        </Link>{' '}
        and{' '}
        <Link to="/resources/how-to-shop-for-a-mortgage/">
          shop for it effectively.
        </Link>
      </p>

      <span className="scenario-radio-label">
        What best describes what you’re looking for?
      </span>

      <RadioGroup
        value={purpose}
        setValue={(purposeValue) => setData({ purpose: purposeValue })}
        values={[
          {
            label: 'Looking for a new mortgage for a new home',
            value: 'Purchase',
          },
          {
            label: 'Seeing if I should refinance my existing mortgage',
            value: 'Refinance',
          },
        ]}
      />

      <span className="scenario-radio-label">
        Do you have an existing offer you’d like to compare against other
        lenders?
      </span>

      <RadioGroup
        value={hasOffer}
        setValue={(isOffer) => setData({ hasOffer: isOffer === 'true' })}
        values={[
          {
            label: 'Yes, I have an offer.',
            value: true,
          },
          {
            label: 'No, I don’t have an offer.',
            value: false,
          },
        ]}
      />

      <StyledButton
        variant="contained"
        onClick={handleNextStep}
        disabled={!purpose || typeof hasOffer !== 'boolean'}
      >
        Let's go!
      </StyledButton>
      <p style={{ marginTop: '16px' }}>
        Mortgages are complicated. We take optimizing mortgage choice off your
        hands. Use our tool to compare mortgage lenders and loan product
        options. We’re here to make apples-to-apples mortgage cost comparison
        easy. We help you{' '}
        <a href="https://www.solve.finance/resources/how-to-shop-for-a-mortgage/">
          shop for a new mortgage
        </a>{' '}
        or{' '}
        <a href="https://www.solve.finance/resources/plain-english/">
          lower the cost of your old one
        </a>
        .
      </p>
    </div>
  );
};

/**
 * The Final Exported Container
 */
const StyledScenario = styled(Scenario)`
  width: 100%;

  #scenario-title {
    font-family: Majorant-Medium;
    font-weight: normal;
    font-size: 2.5em;
    line-height: 46px;
    letter-spacing: -0.04em;
  }

  #scenario-description {
    font-family: Majorant-Medium;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.04em;

    margin: 17px 0 58px;

    max-width: 1100px;
  }

  .scenario-radio-label {
    display: block;
    font-family: Majorant-Medium;
    font-weight: bold;
    font-size: 1em;
    line-height: 150%;

    width: 100%;
  }

  ${RadioGroup} {
    margin-top: 25px;
    margin-bottom: 37px;
  }

  ${StyledButton} {
    width: 154px;
    height: 43px;

    margin-top: 21px;
  }
`;

StyledScenario.displayName = 'Scenario';

export default StyledScenario;
