import { createGlobalStyle } from 'styled-components';

// @ts-ignore
import MajorantMedium from './Majorant-Md.otf';
// @ts-ignore
import MajorantRegular from './Majorant-Rg.otf';

export const viewportMaxWidth = '1300px';

export const widthForIsMobile = 800;

export const maxWidthForIsMobile = `${widthForIsMobile}px`;

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Majorant-Medium";
    font-display: swap;
    font-weight: 400;
    src: local('Majorant-Medium), url(${MajorantMedium}) format("woff2");
  }

  @font-face {
    font-family: "Majorant-Regular";
    font-display: swap;
    font-weight: 400;
    src: local('Majorant-Regular'), url(${MajorantRegular}) format("woff2");
  }


  --font-primary: 'Majorant-Regular';
  --font-secondary: 'Majorant-Medium';


  body {
    font-family: "Majorant-Regular";
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
  }

`;

export default GlobalStyle;
