import mixpanel from 'mixpanel-browser';

mixpanel.init(process.env.GATSBY_MIXPANEL_PROJECT_TOKEN, {
  debug: true,
  track_pageview: true,
  persistence: 'localStorage',
});

export const trackEvent = (name, params) => {
  if (process.env.GATSBY_MIXPANEL_PROJECT_TOKEN) {
    mixpanel.track(name, { ...params });
  }

  if (typeof window.gtag === 'function') {
    window.gtag('event', name, { ...params });
  }
};
